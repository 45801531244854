<template>
  <v-main>
    <v-container>
      <v-card max-width="900" class="mx-auto pa-5">
        <v-card-title> Comments / Suggestions / Help Request </v-card-title>
        <v-container>
          <contact-form></contact-form>
        </v-container>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Contact Us'
    };
  },
  components: {
    ContactForm: () => import('@/components/ContactForm')
  }
};
</script>

<style scoped>
.email {
  font-size: 1.4rem;
}
</style>
